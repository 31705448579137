import React, { FC } from "react";
import yn from "yn";
import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import { Grid } from "@mui/material";
import { useCountry } from "hooks/useCountry";
import { europeanCountries } from "resources/countries-config.json";
import { useEnv } from "hooks/useEnv";
import classNames from "classnames";

export interface EmailSignupSkeletonProps {
  className?: string;
}

export const EmailSignupSkeleton: FC<EmailSignupSkeletonProps> = ({
  className
}) => {
  const env = useEnv();
  const country = useCountry();

  const isEuCountry =
    europeanCountries.includes(country) &&
    !yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE);
  window.console.log("isEuCountry", isEuCountry);
  return (
    <Grid container item className={className}>
      <Grid item xs={12} sm={12} md={6}>
        <Skeleton
          variant="rectangular"
          width="100%"
          className={"h-[260px] !top-0 md:h-[100%]"}
        ></Skeleton>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Grid
          className={classNames([
            `flex flex-col justify-between items-center px-[20px] py-[40px] sm:px-[30px] sm:py-[50px] lg:px-[40px] lg:py-[100px]`,
            {
              [`px-[20px] py-[40px] pb-[16px] sm:px-[30px] sm:py-[50px] sm:pb-[12px] lg:px-[40px] lg:py-[100px] lg:pb-[12px]`]:
                isEuCountry
            }
          ])}
        >
          <Grid item className={"w-full h-full flex flex-col items-center"}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={330}
              className={"mb-[20px]"}
            />
            <Skeleton variant="rectangular" width="70%" height={45} />
          </Grid>
          {!yn(env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE) &&
            europeanCountries.includes(country) && (
              <Skeleton variant="rectangular" width="60%" height={25} />
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailSignupSkeleton;
