import { useMutation } from "@apollo/client";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Controller, useForm } from "react-hook-form";
import { RHFormTextField } from "@RHCommerceDev/component-rh-text-field";
import { emailRegex } from "@RHCommerceDev/utils/regex";
import RHLink from "@RHCommerceDev/component-rh-link";

import {
  COOKIE_WITH_SESSION,
  EU_COUNTRIES,
  FONT_BARON_SANS_LIGHT,
  PRIVACY_URL
} from "@RHCommerceDev/utils/constants";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import classNames from "classnames";
import { mutationSubscribeToBrands } from "@RHCommerceDev/graphql-client/queries/email-subscription";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import React, { useCallback, useMemo, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import useCheckEmailPopUp from "@RHCommerceDev/hooks/useCheckEmailPopUp/useCheckEmailPopUp";
import { getSourceCode } from "@RHCommerceDev/utils/getSourceCode";

interface EmailSignupProps {
  onCloseModal?: () => void;
  emailSignUpContent?: { [key: string]: any };
}

type SignUpFormType = {
  [brand: string]: boolean;
} & {
  email: String;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailForm: {
      maxWidth: "445px",
      margin: "0 auto"
    },
    emailSuccess: {
      [theme.breakpoints.down("sm")]: {
        padding: "130px 0px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "90px 00px"
      }
    },
    errorWrap: {
      color: " #ca6667",
      lineHeight: "1.29",
      letterSpacing: "2px",
      flexDirection: "column",
      maxWidth: "445px"
    },
    errorText: {
      marginLeft: "5px",
      marginTop: "3px"
    },
    thankyouText: {
      width: "200px",
      lineHeight: "1.29",
      letterSpacing: "2px",
      minHeight: "300px",
      margin: "auto",
      textAlign: "center",
      textTransform: "uppercase",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: "445px"
    },
    title: {
      marginBottom: "10px",
      textTransform: "uppercase"
    },
    subTitle: {
      color: "#666666",
      lineHeight: "18px",
      textAlign: "center",
      width: "100%",
      margin: "0px auto 18px",
      fontSize: "12px",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT,
      [theme.breakpoints.down("xs")]: {
        width: "65%"
      }
    },
    termWrap: {
      maxWidth: "330px",
      margin: "auto",
      paddingTop: "24px",
      marginBottom: "32px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "18px",
        marginBottom: "26px"
      },

      "& .MuiFormControlLabel-root": {
        marginBottom: "24px"
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "10px",
        lineHeight: "15px",
        fontWeight: 300,
        fontFamily: FONT_BARON_SANS_LIGHT
      },
      "& .MuiCheckbox-root": {
        width: "21px",
        height: "21px"
      }
    },
    termText: {
      fontSize: "10px",
      lineHeight: "15px",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT,
      letterSpacing: "0.015em",
      textAlign: "center"
    },
    link: {
      fontSize: "10px !important"
    },
    errorMsg: {
      color: "#ca6667",
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "12px",
      letterSpacing: "0.04em",
      textAlign: "center",
      margin: "10px"
    },
    button: {
      minWidth: "228px",
      margin: "0 auto 20px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "16px"
      }
    },
    unsubscribeText: {
      fontSize: "11px",
      lineHeight: "12px",
      textAlign: "center",
      textTransform: "uppercase",
      color: " #666666",
      fontWeight: 300,
      fontFamily: FONT_BARON_SANS_LIGHT
    }
  })
);

const EmailSignup: React.FC<EmailSignupProps> = ({
  onCloseModal,
  emailSignUpContent
}) => {
  const classes = useStyles();
  const typoStyleClasses = useTypographyStyles({
    keys: [
      "rhBaseBody1",
      "rhBaseH1",
      "textAlignCenter",
      "rhBaseLink2",
      "rhBaseCaption11"
    ]
  });
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorAlertList, setErrorAlertList] = useState<string[]>([]);
  const { handleSubmit, errors, control, getValues } = useForm({
    mode: "onBlur"
  });
  const country = useCountry();
  const locale = useLocale();
  const { resetCookieClicked } = useCheckEmailPopUp();

  const isSubscriptionCountry = useMemo(() => {
    return !!EU_COUNTRIES.includes(country);
  }, [country]);

  const [subscribeToBrands, { loading: loadingSubmit }] = useMutation<Mutation>(
    mutationSubscribeToBrands,
    {
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onCompleted: data => {
        setSuccess(true);
        setTimeout(() => {
          if (onCloseModal) onCloseModal();
        }, 4000);
        if (data?.subscribeToBrands) {
          const brands = Object.entries(data?.subscribeToBrands).filter(
            brand => brand[1] === true
          );
          const brandsName = brands.map(name => name[0]).join(",");
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.EMAIL_SUBSCRIPTION.INT_TYPE,
              {
                brands: {
                  subscribedbrands: brandsName
                },
                isEmailMarketingPopup: true
              }
            )
          );
        }
      },
      onError: () => {
        setErrorAlertList(["Error"]);
      }
    }
  );

  const showTermsRequiredMsg = () => {
    const values = getValues() || {};
    return !!(isSubmitted && !values.terms);
  };

  const onSubmit = useCallback(
    (formValue: SignUpFormType) => {
      if (isSubscriptionCountry) {
        setIsSubmitted(true);
        if (!formValue.terms) return;
      }
      setErrorAlertList([]);
      setSuccess(false);
      resetCookieClicked?.();
      subscribeToBrands({
        variables: {
          brands: ["RH"],
          country: country,
          email: formValue.email,
          locale,
          sourceCode: getSourceCode({
            place: "EMAIL_SIGN_UP_DIALOG"
          })
        }
      });
    },
    [subscribeToBrands, country, locale, COOKIE_WITH_SESSION]
  );

  return (
    <>
      <Grid
        container
        className={classNames([
          classes.emailForm,
          success && classes.emailSuccess
        ])}
      >
        {errorAlertList.length > 0 && (
          <Grid className={classes.errorWrap}>
            <WarningIcon />
            <span className={classes.errorText}>
              {emailSignUpContent?.ERROR}
            </span>
          </Grid>
        )}

        {success ? (
          <Grid container item alignItems="center" justifyContent="center">
            <Typography
              className={classNames([
                typoStyleClasses.textAlignCenter,
                typoStyleClasses.rhBaseH1,
                classes.title
              ])}
            >
              {emailSignUpContent?.THANKYOU_TITLE}
            </Typography>

            <Typography className={classNames([classes.subTitle])}>
              {emailSignUpContent?.THANKYOU_SUBTEXT}
            </Typography>
          </Grid>
        ) : (
          <ValidatorForm
            debounceTime={1000}
            instantValidate={false}
            onSubmit={handleSubmit(onSubmit)}
            data-testid="rh-email-signup-form-container"
            validationErrors={errorAlertList}
            className={`${success && "success"}`}
          >
            <Typography
              className={classNames([
                typoStyleClasses.textAlignCenter,
                typoStyleClasses.rhBaseH1,
                classes.title
              ])}
            >
              {emailSignUpContent?.TITLE}
            </Typography>

            <Typography className={classNames([classes.subTitle])}>
              {emailSignUpContent?.SUB_TITLE}
            </Typography>

            <RHFormTextField
              control={control}
              fullWidth
              name="email"
              InputProps={{
                autoComplete: "off"
              }}
              placeholder={emailSignUpContent?.EMAIL}
              error={errors.email}
              rules={{
                required: emailSignUpContent?.EMAIL_ERROR,
                pattern: {
                  value: emailRegex,
                  message: emailSignUpContent?.EMAIL_ERROR
                }
              }}
            />

            <Grid className={classes.termWrap}>
              {isSubscriptionCountry && (
                <Controller
                  control={control}
                  fullWidth
                  name="terms"
                  render={({ onChange, onBlur, value }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          aria-label="terms and conditions"
                          id="terms"
                          onBlur={onBlur}
                          checked={value || ""}
                          onChange={e => onChange(e.target.checked)}
                          sx={{
                            "& .PrivateSwitchBase-input": {
                              position: "inherit",
                              minWidth: "19px"
                            },
                            "& .MuiSvgIcon-root": {
                              marginRight: "19px"
                            }
                          }}
                        />
                      }
                      label={emailSignUpContent?.SUBSCRIPTION_AGREE}
                      color="primary"
                    />
                  )}
                />
              )}

              <Grid className={classes.termText}>
                <span>{emailSignUpContent?.TERMS_AGREE} </span>
                <RHLink
                  underline="always"
                  display="inline"
                  target="_blank"
                  to={PRIVACY_URL}
                  className={classNames([
                    typoStyleClasses.rhBaseLink2,
                    classes.link
                  ])}
                >
                  {emailSignUpContent?.RH_PRIVACY_NOTICE}
                </RHLink>
              </Grid>
            </Grid>

            {showTermsRequiredMsg() && (
              <Typography className={classNames([classes.errorMsg])}>
                {emailSignUpContent?.TERMS_REQUIRED}
              </Typography>
            )}

            <Grid item container justifyContent="center">
              <Button
                type="submit"
                name="submit"
                data-testid="return-update-email-button"
                variant="contained"
                color="primary"
                className={classNames([
                  classes.button,
                  typoStyleClasses.rhBaseCaption11
                ])}
                disabled={loadingSubmit}
              >
                {emailSignUpContent?.SIGNUP_FOR_EMAIL}
              </Button>
            </Grid>

            <Typography className={classNames([classes.unsubscribeText])}>
              {emailSignUpContent?.UN_SUBSCRIBE}
            </Typography>
          </ValidatorForm>
        )}
      </Grid>
    </>
  );
};
export default React.memo(EmailSignup);
